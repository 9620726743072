import * as React from "react";
import Header from "../components/header";
import "@fontsource/roboto/900-italic.css";
import "@fontsource/roboto/400.css";
import "../styles/content.scss";
import "../styles/register.scss";
import Footer from "../components/footer";
import bullet from "../images/tick.png";
import Seo from "../components/helmet";

const bullets = ["Choice ", "Transparency", "Predictability", "Flexibility"];

const RegisterPage = () => {
  return (
    <React.Fragment>
      <Seo title="Register Buyer - Flock Finance"></Seo>
      <Header></Header>

      <title>Register</title>

      <h1 className="title-banner">REGISTER</h1>

      <div className="button-toggle">
        <a className="button-clear" href="/register-seller">
          SELLER&nbsp;&nbsp;&nbsp;
        </a>
        <div className="button-dark">BUYER</div>
      </div>

      <div className="title-message">
        Thank you for choosing to register with Flock - welcome to the
        community!
        <br />
        <br />
        Please select either Buyer or Seller and fill in the details below. One
        of our friendly staff will contact you to finalise the registration
        process.
      </div>

      <div className="register-go">
        <div className="explainer">
          <div className="exp-title">BUYERS</div>
          <div className="exp-content">
            Are you an individual looking to find stable but healthy returns
            from easy to understand investments?
            <br />
            <br />
            Flock is levelling the playing field. We’re starting by making the
            buying and selling of invoices an open and accessible marketplace
            for individual investors and businesses of all sizes. This means
            more options, better rates and more predictable returns.
          </div>
          <div className="exp-bullets">
            {bullets.map((b, i) => (
              <div className="exp-bullet" key={i}>
                <img src={bullet} />
                <p>{b}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="form">
          <iframe
            src="https://tally.so/embed/3xXdkG?hideTitle=1&transparentBackground=1"
            width="100%"
            height="500"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            title="Register Buyers"
          ></iframe>
        </div>
      </div>

      <Footer></Footer>
    </React.Fragment>
  );
};

export default RegisterPage;
